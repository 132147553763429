.products-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filters{
    .category{
        display: inline-block;
        margin: 5px;
    }

    .sort{
        display: inline-block;
        margin: 5px;
    }
}