.nav-button{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    .lines-container{
        background-color: #000000;
        border: none;
        outline: none;
        cursor: pointer;
        .nav-line{
            background-color: #F5CB5C;
            display: block;
            width: 40px;
            height: 2px;
            margin: 8px;
            border-radius: 2px;
            transition: transform, background-color;
            transition-timing-function: ease;
            transition-duration: 400ms;
        }
    }
}

.nav-button-toggled{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    .lines-container{
        background-color: #000000;
        border: none;
        outline: none;
        cursor: pointer;
        .nav-line{
            background-color: #F5CB5C;
            display: block;
            width: 40px;
            height: 2px;
            margin: 8px;
            border-radius: 2px;
            transition: transform, background-color;
            transition-timing-function: ease;
            transition-duration: 400ms;
        }
        .line-top{
            transform: rotateZ(45deg) translateY(14.5px);
        }
        .line-middle{
            transition: opacity ease-in-out 400ms;
            opacity: 0;
        }
        .line-bottom{
            transform: rotateZ(-45deg) translateY(-14.5px);
        }
    }
}