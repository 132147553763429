.cart-bar{
    position: fixed;
    overflow: auto;
    top: -710px;
    max-height: 600px;
    z-index: 3;
    background: #333533;
    width: 100%;
    padding: 10px 0px 10px 0px;
    color: #F5CB5C;
    transition: top 400ms ease;
    .cart-title{
        padding: 0px 10px 0px 10px;
    }

    .empty{
        color: white;
        padding: 10px 0px;
        font-size: 1.5rem;
    }

    .total-price{
        text-align: center;
        font-size: 1.3rem;
    }
    .cartbar-button-container{
        display: flex;
        justify-content: center;
        .cart-button{
            background: black;
            border: 3px solid #F5CB5C;
            border-radius: 3px;
            color: white;
            font-size: 1rem;
            height: 2.5rem;
        }
    }
    box-shadow: 0px 0px 30px 20px #000000;
}

.cart-down{
    transition: top 400ms ease;
    top: 110px;
}

@media(max-height: 680px){
    .cart-bar{
        max-height: 500px;
    }
}