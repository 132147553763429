.product-card-container{
    display: inline-block;
    a:link{
        text-decoration: none!important;
    }
    .product-card{
        color: white;
        width: 180px;
        aspect-ratio: 2/3;
        border: 1px black solid;
        margin: 5px;
        position: relative;
        overflow: hidden;
        .banner{
            padding: 0;
            z-index: 1;
            position: relative;
            width : 50%;
            border-radius: 0px 40px 40px 0px;
            margin: 2px 0px 2px 0px; 
        }
        .top-banner{
            background-color: #272727;
            color: white;
        }
        .bottom-banner{
            background-color: #F5CB5C;
            color: black;
        }
        .product-image-container{
            position: absolute;
            width: 100%;
            height: 100%;
            .product-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
            }
        }
        .product-card-details-container{
            transition: 400ms;
            width: 100%;
            position: absolute;
            bottom: -40px;
            left: 0;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            justify-content: space-around;
            height: 100%;
            background: linear-gradient(to bottom, transparent 30%, black 90%);
            a{
                width: 100%;
                height: 100%;
                .product-card-title-container{
                    color: white;
                    height: 100%;
                    min-width: 100%;
                    display: flex;
                    align-items: flex-end;
                    .product-card-title{
                        margin: 0;
                    }
                }
            }
            .price-container{
                width: 100%;
            }
            .product-card-buttons-container{
                display: flex;
                justify-content: space-evenly;
            }
        }
    }
}

.product-card:hover > .product-card-details-container{
    transform: translateY(-40px);
    transition: 400ms;
}

@media(max-width: 380px) {
    .product-card-container{
        .product-card{
            margin: 1px;
        }
    }
}