.button-right{
    background-color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
    width: 42px;
    height: 80px;
    border: 1px #000000 solid;
    border-radius: 2px;
    padding: 0;
    .line-top-right{
        transform: rotate(45deg);
        transform-origin: 36px -5px;
    }
    .line-bottom-right{
        transform: rotate(-45deg);
        transform-origin: 34px 8px;
    }
}
.button-left{
    background-color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
    width: 42px;
    height: 80px;
    border: 1px #000000 solid;
    border-radius: 2px;
    padding: 0;
    .line-top-left{
        transform: rotate(-45deg);
        transform-origin: 5px -1px;
    }
    .line-bottom-left{
        transform: rotate(45deg);
        transform-origin: 6px 4px;
    }
}

.button-line{
    background-color: #F5CB5C;
    display: block;
    width: 40px;
    height: 2px;
    border: 1px #F5CB5C solid;
    border-radius: 2px;
}