.swiper-card {
    width: 100%;
    aspect-ratio: 2/1;  
    color: white; 
    overflow-wrap: break-word !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .details-container{
        border-radius: 3px;
        overflow: hidden;
        padding: 7px;
        width: calc(100% - 14px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        left: 0rem;
        bottom: 0rem;
        
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.0) 100%);
        .title-container {
            padding: 5px; 
            grid-area: title-container;
            .title{
                margin: 0 !important;
                font-size: 2rem;   
            } 
        }
        .description-container { 
            grid-area: description-container; 
        }
        .price-container {
            display: grid; 
            grid-template-columns: fit-content(300px) fit-content(300px); 
            grid-template-rows: fit-content(300px) fit-content(300px); 
            gap: 0px 0px; 
            grid-template-areas: 
              "new-price-container discount-container"
              "old-price-container discount-container";  
        }
        .new-price-container { 
            padding: 5px;
        }
        .old-price-container { 
            padding: 5px;
            .price-cut{
                text-decoration: line-through;
            }
        
        }
        .discount-container { 
            grid-area: discount-container;
            padding: 5px;
        }
        .image-container { 
            grid-area: image-container;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        p{
            margin: 0 !important;
            font-size: 1.2rem !important;
        }
    } 
}


a:link{
    text-decoration: none!important;
}

@media(min-width: 500px) {
    .swiper-card{
        
    }
    
}