.order-card{
    border: 2px solid black;
    border-radius: 3px;
    padding: 3px;
    margin: 3px;
    max-width: calc(100% - 16px);
    
    hr{
        height: 0px;
        border: 1px solid black;
    }

    .shipping-address{
        div{
            padding: 0px 5px;
        }
    }

    .products-quantities{
        display: flex;
        .prod{
            margin: 2px;
            display: inline-block;
            padding: 3px;
            border: 2px solid black;
        }
    }

    .admin-input{
        width: 100%;
        padding: 8px 4px;
        font-size: 1rem;
        box-sizing: border-box;
        resize: none;
        margin-bottom: 5px;
    }
}

@media (min-width: 500px) {
    .order-card{
        margin: 3px;
        width: 500px;
        display: inline-block;
    }
}