.firstVisit-modal-container{
    background: #333533;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .behind-modal{
        object-fit: cover;
        z-index: 0;
        position: fixed;
        animation-name: scale;
        animation-duration: 1s;
        transform: scale(0.3);
    }
    .modal{
        aspect-ratio: 1/1;

        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: black;
        color: white;
        padding: 12px;
        border: 4px solid #F5CB5C;
        border-radius: 100%;
        animation-name: opacity;
        animation-duration: 0.4s;
        .modal-logo{
            max-width: 180px;
        }
        .modal-title{
            margin-top: 0;
            font-size: 2.5rem;
        }
        .modal-buttons{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            .modal-button{
                cursor: pointer;
                font-size: 1.5rem;
                padding: 10px 20px;
                background: #242423;
                border-radius: 3px;
                color: white;
            }
            .modal-button-red{
                border: 2px solid rgb(175, 0, 0);
            }
            .modal-button-green{
                border: 2px solid rgb(0, 160, 0);
            }
        }
    }
}

@keyframes scale {
  from {transform: scale(0.1);}
  to {transform: scale(0.3);}
}

@keyframes opacity {
    from {
        opacity: 0;
        transform: scale(0.2);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}