.divider {
    display: flex;

    &:before,
    &:after {
      content: "";
      flex: 1;
    }
    margin: 2px 0px 2px 0px;
    border-radius: 3px;
}

.do-not-cross {
    overflow: hidden;
    align-items: center;
    background: #F5CB5C;
    color: black;
    height: 2em;
    line-height: 2em;
    
    h2{
      z-index: 2;
      font-size: 1em;
    }
    &:before,
    &:after {
      z-index: 1;
      background: #E8EDDF;
      padding: 45px 0;
      height: 0;
      transform: rotate(45deg);
    }
}