.sign-in-form-container{
    .error-container{  
        display: flex;  
        justify-content: center;
        .error{
            font-size: 1rem;
            color: black;   
        }     
    }
    margin-top: 2rem;
    form{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .input-container{
            padding: 0rem 1rem 1rem 0rem;
        }
        .text-input{
            border: none;
            background: transparent;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            outline: transparent;
        }
        .bottom-border{
            display: block;
            width: 25%;
            height: 2px;
            background-color: #F5CB5C;
            transition: width 400ms ease-in-out;
        }
        .label{
            font-size: 1.2rem;
        }
        .button-container{
            margin-top: 0.5rem;
            input{
                border: 1px solid black;
                border-radius: 2px;
                background-color: black;
                color: white;
                height: 3rem;
                width: 8rem;
                font-size: 1rem;
                overflow: visible;
            }
        }
        .text-input:hover + .bottom-border{
            width: 50%;
        }
        .text-input:focus + .bottom-border{
            width: 100%;
        }
    }
}
.break {
    flex-basis: 100%;
    height: 0;
}

.inactive{
    display: none;
}