.signin-signup-body-container{
    width: 100%;
    height: calc(100% - 110px);
    position: absolute;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    top: 110px;
    background-color: #E8EDDF;
    p{
        font-size: 2rem;
    }
    transition-duration: 400ms;
}

.signin-signup-body-container-to-right{
    width: 100%;
    height: calc(100% - 110px);
    position: absolute;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    top: 110px;
    background-color: #E8EDDF;
    p{
        font-size: 2rem;
    }
}

@media (min-width: 500px){
    .signin-signup-body-container-to-right{
        position: absolute;
        width: 100%;
        height: calc(100% - 110px);
        top: 110px;
        background-color: #E8EDDF;
        p{
            font-size: 2rem;
        }
        transform: translateX(500px);
        transition-duration: 400ms;
    }
}

@media (max-width: 500px){
    .signin-signup-body-to-right{
        position: absolute;
        height: calc(100vh - 110px);
        top: 110px;
        background-color: #E8EDDF;
        p{
            font-size: 2rem;
        }
        transform: scale(0.9);
        transition-duration: 400ms;
    }
}
.main-body{
    max-width: 100%;   
}

@media (min-width: 500px){
    .main-body{
        max-width: calc(100vw - 20%);        
    }
}

.title-log-out{
    display: flex;
    align-items: center;
    justify-content: space-around;
    .log-out-container{
        padding: 5px;
        .log-out{
            cursor: pointer;
            border: 2px solid #F5CB5C;
            border-radius: 3px;
            background: #242423;
            color: white;
            font-size: 1rem;
            padding: 7px 15px;
        }
    }
}

.account-order-card{
    margin: 3px;
    border: 3px solid black;
    border-radius: 3px;
    padding: 5px;
    hr{
        background: black;
        border: 1px solid black;
    }
    .order-products-container{
        display: flex;
        flex-wrap: wrap;
        .order-products-title{
            flex: 0 0 100%;
        }
    }
}

.order-product-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid black;
        margin: 2px;
        width: 100px;
        height: 150px;
        background: #CFDBD5;
        position: relative;
        overflow: hidden;
        .order-image{
            max-height: 150px;  
            object-fit: cover;
            position: absolute;
            z-index: 0;
        }
        .order-product-title{
            z-index: 1;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            opacity: 1;
            scale: 100%;
            transition: 400ms;        
        }
    }
}

.product:hover > .order-product-title{
    opacity: 0;
    scale: 150%;
    transition: 400ms;
}