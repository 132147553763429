body{
    margin: 0px;
    overflow-x: hidden;
    width: 100vw;
    background-color: #E8EDDF;
    font-family: 'Rubik' !important;
}


::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
::-webkit-scrollbar-thumb {
    background: #F5CB5C; 
    border-radius: 5px;
}
  
::-webkit-scrollbar-thumb:hover {
    background: #888; 
}

::-webkit-scrollbar-track {
    background: #242423;
}

.card{
    border-radius: 3px;
}

.ReactModal__Overlay--after-open{
    z-index: 2;
}