.sidebar{
    width: 500px;
    height: calc(100% - 110px);
    left: 0px;
    bottom: 0px;
    background-color: #333533;
    position: fixed;
    z-index: -2;
    box-shadow: -10px 10px 15px 10px #272727 inset;
    overflow-y: scroll;
}

.sidebar-open{
    width: 500px;
    height: calc(100% - 110px);
    left: 0px;
    bottom: 0px;
    background-color: #333533;
    position: fixed;
    z-index: -2;
    box-shadow: -10px 10px 15px 10px #272727 inset;
    overflow-y: scroll;
}

@media (max-width: 500px) {
    .sidebar{
        width: 100vw;
        height: calc(100% - 110px);
        top: 110px;
        left: 0px;
        background-color: #333533;
        position: fixed;
        z-index: 1;
        box-shadow: -10px 10px 15px 10px #272727 inset;
        transition-duration: 400ms;
        transform: translateY(-150vh);
    }

    .sidebar-open{
        width: 100vw;
        height: calc(100% - 110px);
        top: 110px;
        left: 0px;
        background-color: #333533;
        position: fixed;
        z-index: 1;
        box-shadow: -10px 10px 15px 10px #272727 inset;
        transition-duration: 400ms;
    }

    .sidebar-cards-container{
        margin-top: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: grid;
        grid-gap: 7px;
        justify-content: center;
    }
}

.sidebar-cards-container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: grid;
    grid-gap: 7px;
    justify-content: center;
}
