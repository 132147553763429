body{
    .not-found{
        height: 100vh;
        background-color: black !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: white;
        .home-link{
            color: #F5CB5C;
            .logo-image{
                width: 100%;
            }
        }
    }
}