.status{
    display: none;
    margin-bottom: 5px;
    border: 2px solid black;
    border-radius: 2px;
    padding: 3px;
    font-size: 1.25rem;
}

.show{
    display: flex;
    display: flex; 
    justify-content: center;
}

.green{
    border: 2px solid green;
    color: green;
    background: rgba(0, 128, 0, 0.041);
}

.red{
    border: 2px solid red;
    color: red;
    background: rgba(255, 0, 0, 0.096);
}