.categories-card-container{
    a:link{
        text-decoration: none!important;
    }
    .categories-card{
        position: relative;
        width: 40vw;
        aspect-ratio: 2/3;
        border-color: black;
        border-width: 1px;
        color: white !important;
        border-style: solid;
        margin: 5px;
        transform: scale(1.0);
        transition: 400ms;
        overflow: hidden;
        .categories-card-title-container{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.0) 100%);
            .categories-card-title{
                position: absolute;
                margin: 0;
                bottom: 1rem;
                left: 0.5rem;
            }
        }

        .categories-card-image-container {
            position: absolute; 
            width: 100%;
            height: 100%;
            transform: scale(1.20);
            transition: 400ms;
            //scale image down on hover, up on standard
            //object-fit: cover;
            .categories-image{
                z-index: -2;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.categories-card:hover{
    transform: scale(1.03);
    transition: 400ms;
    box-shadow: 0px 0px 10px 5px #272727;
}

.categories-card:hover > .categories-card-image-container{
    transform: scale(1.00);
    transition: 400ms;
}

@media (min-width: 500px){
    .categories-card-container{
        .categories-card{
            width: 15vw;
        }
    }
}

