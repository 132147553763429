.delete-button{
    border: 2px solid rgb(175, 0, 0);
    border-radius: 30px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(175, 0, 0);
    cursor: pointer;
    svg{
        height: 20px;
        width: 20px;
        path{
            stroke: white;
        }
    }
}