.switcher-container{
    position: static;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
    height: 42px;
    width: 370px;
    .switcher-buttons-container{
        display: flex;
        justify-content: center;
        .switcher-button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            font-size: 1.2rem;
            border: none;
            outline: none;
            color: black;
            width: 185px;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
        }
    
        .active{
            color: #242423;
            font-weight: bold;
        }
    }
    .switcher-line-container{
        clear: left;
        width: 370px;
        .switcher-line{
            display: block;
            height: 2px;
            width: 185px;
            background-color: #F5CB5C;
            transition-duration: 400ms;
        }
        .line-left{
            transition-duration: 400ms;
        }
        .line-right{
            transform: translateX(185px);
        }
    }
}