.error{
    margin: 20px 2px 20px 2px;
    font-size: 2rem;
    padding:  7px;
    background: rgba(255, 0, 0, 0.808);
    border: 2px solid black;
    border-radius: 3px;
}

.product-title{
    font-size: 3rem;
    margin: 20px 7px 0px 7px;
}

.link-container{
    margin: 0px 7px 2px 7px;
    .link{
        margin: 0px 3px 0px 3px;
        color: rgb(92, 92, 92);
    }
}
.stoc-and-price-container{
    margin: 0px 5px 0px 5px;
    padding: 10px;
    background: black;
    border: 0px solid rgba(133, 133, 133, 0.0);
    border-radius: 5px;
    .quantity{
        text-align: center;
        color: white;
    }
    .yellow{
        color: #F5CB5C;
    }
    .red{
        color: red;
    }
    .price-add-to-cart{
        display: flex;
        align-items: center;
        justify-content: center;
        .price-container{
            padding: 0px 10px 0px 10px;
            .price{
                font-size: 2rem;
                font-weight: bold;
                padding: 5px 0px 0px 0px;
                color: white;
            }
            .discount-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 5px;
                .old-price{
                    font-size: 1rem;
                    color: red;
                    text-decoration: line-through;
                }
                .discount{
                    background: #F5CB5C;
                    padding: 3px;
                    border: 0px solid rgba(133, 133, 133, 0.0);
                    border-radius: 5px;
                }
            }
        }
    }
}

.details{
    margin-top: 10px;
    padding: 5px;
    
    .description-title{
        font-size: 2rem;
        width: 50%;
        display: inline-block;
    }
    .description{
        font-size: 1.25rem !important;
    }
    max-height: calc(2rem + 64px);
    overflow: hidden;
    transition: max-height 400ms ease-in-out;
}

.title-container{
    display: flex;
    .arrow-container{
        margin-left: auto;
        margin: 32px 15px 32px auto;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        svg{
            width: 2rem;
            height: 2rem;
            path{
                stroke: #F5CB5C;
                fill: #F5CB5C;
            }
        }
        transition-duration: 400ms;
    }
    .down{
        transform: rotate(180deg);
        transition-duration: 400ms;
    }
}

.recomended{
    .recomended-title{
        font-size: 2rem;
    }
    max-height: calc(2rem + 64px);
    overflow: hidden;
    transition: max-height 400ms ease-in-out;
    padding-bottom: 5px;
}

.open{
    max-height: 100%;
}

.border-top{
    border-top: 2px solid #333533;
}

.product-image{
    max-width: 100%;
}

.product-swiper{
    border: 0px solid rgba(133, 133, 133, 0.0);;
    border-radius: 10px;
    max-width: 400px;
}

@media (min-width: 500px){
    .main-body{
        .swiper-price-container{
            display: flex;
            justify-content: center;
            align-items: center;
            .swiper{
                margin: 5px;
            }
            .price-add-to-cart{
                margin: 5px;
                display: flex;
                flex-direction: column;
                .price-container{
                    align-self: flex-start;
                }
            }
        }
    }
}