.top-products-container{
    width: 100vw;
    position: relative;
    .products-card-container{
        padding: 15px 45px 30px 45px;
        //width: 100%;
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth !important;
    }
    .left-button-container{
        position: absolute;
        top: calc(50% -  40px);
        left: 0;
    }
    .right-button-container{
        position: absolute;
        top: calc(50% -  40px);
        right: 0;
    }
}


.products-card-container::-webkit-scrollbar-track{
    background: #E8EDDF !important;
}   
.products-card-container::-webkit-scrollbar-thumb{
    height: 5px;
}
.products-card-container::-webkit-scrollbar{
    height: 5px;
}

@media (min-width: 500px){
    .top-products-container{
        max-width: 100%;        
    }
}