.main-body-container{
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 110px;
    background-color: #E8EDDF;
    p{
        font-size: 2rem;
    }
    transition-duration: 400ms;
}

@media (min-width: 500px){
    .main-body-container-to-right{
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        top: 110px;
        background-color: #E8EDDF;
        p{
            font-size: 2rem;
        }
        transform: translateX(500px);
        transition-duration: 400ms;
    }
}

@media (max-width: 500px){
    .main-body-container-to-right{
        position: absolute;
        top: 110px;
        background-color: #E8EDDF;
        margin: 0;
        p{
            font-size: 2rem;
        }
        transform: scale(0.9);
        transition-duration: 400ms;
    }
}
.main-body{
    width: 100%;
    min-height: calc(100vh - 110px);   
}

@media (min-width: 500px){
    .main-body{
        max-width: calc(100vw - 20%);
        .hero-section{
            padding-top: 8px;
            display: flex;
        }        
    }
}
