.swiper-container{
    width: 100vw;
    aspect-ratio: 2/1;
    border-radius: 3px;
    .swiper{
        width: 100%;
        .swiper-button-next,
        .swiper-button-prev{
            color: #242423 !important;
        }
    }
}

@media (min-width: 500px){
    .swiper-container{
        width: 100%;
        aspect-ratio: 2/1;
        .swiper{
            width: 100%;
            .swiper-button-next,
            .swiper-button-prev{
                color: #F5CB5C !important;
            }
        }
    }
}