.page{
    background: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .image-container{
        img{
            max-width: 300px;
        }
    }
    .text-container{
        color: white;
        padding: 15px;
    }
}