.order-title{
    font-size: 2.5rem;
}
.order-section{
    .title-container{
        padding: 0px 5px;
        display: flex;
        align-items: center;
        .section-title{
            font-size: 1.5rem;
            display: inline-block;
        }
    }
    .contents{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 10px;
    }
    transition: max-height 400ms ease-in-out;
    max-height: 96px;
    overflow: hidden;
}

form{
    width: 370px;
}

.address-label{
    display: none;
}

.radio-buttons{
    flex-direction: column;
    align-content: center !important;
    .radio-label{
        font-size: 1.3rem;
        .radio-button {
            width: auto;
        }
    }
}

.order-input{
    width: 100%;
    padding: 0px 4px;
    font-size: 1.2rem;
    border: none;
    outline: none;
    margin-bottom: 5px;
    background: transparent;
}

.bottom-border{
    display: block;
    width: 25%;
    height: 2px;
    background-color: #F5CB5C;
    transition: width 400ms ease-in-out;
    margin-bottom: 20px;
}

select:hover + .bottom-border{
    width: 50%;
}

input:hover + .bottom-border{
    width: 50%;
}

select:focus + .bottom-border{
    width: 100%;
}

input:focus + .bottom-border{
    width: 100%;
}

.open{
    transition: max-height 400ms ease-in-out;
    max-height: 100%;
}

.down{
    transform: rotate(180deg);
    transition-duration: 400ms;
}

.order-price-submit{
    display: flex;
    align-items: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
    background: #333533;
    .final-value{
        margin-left: auto;
        margin-right: 4px;
        color: white;
        font-size: 1.3rem;
        display: inline-block;
    }
    .submit-order{
        cursor: pointer;
        margin-right: auto;
        margin-left: 4px;
        display: inline-block;
        background: black;
        border: 3px solid #F5CB5C;
        border-radius: 3px;
        color: white;
        font-size: 1rem;
        height: 2.2rem;
    }
    .disabled{
        border: 2px solid red;
        background: gray;
    }
    box-shadow: 0px 0px 20px 10px #000;
}

.empty{
    height: 80px;
}

@media (min-height: 700px){
    .order-price-submit{
        height: 5rem;
        .final-value{
            font-size: 2rem
        }
        .submit-order{
            font-size: 1.5rem;
            min-height: 4.5rem;
            padding: 0px 15px;
        }
    }
    .empty{
        height: 8rem;
    }
}

.no-auth{
    font-size: 1.3rem; 
    padding: 5px;
}

.success{
    font-size: 2rem;
    color: rgb(0, 128, 0);
    border: 2px solid green;
    background: rgba(0, 128, 0, 0.1);
    margin: 3px;
    padding: 5px;
}