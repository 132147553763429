.product-container{
    padding: 3px;
    border: 2px solid black;
    border-radius: 2px;
    max-width: calc(100% - 12px);
    margin-bottom: 3px;
    
    form{
        width: 100%;
    }

    p{
        margin: 5px 0px 1px 0px;
        font-size: 1.25rem;
    }

    .admin-input{
        width: 100%;
        padding: 8px 4px;
        font-size: 1rem;
        box-sizing: border-box;
        resize: none;
        margin-bottom: 5px;
    }

    textarea{
        height: 150px
    }

    .updated{
        background: green;
        margin: 0;
        color: white;
        border-radius: 3px;
        padding: 3px;
    }

    .title{
        text-align: center;
        font-size: 2rem;
        margin: 15px 0px 5px 0px;
    }

    hr{
        height: 0px;
        border: 1px solid black;
    }

    .image-container{
        margin: 2px;
        position: relative;
        display: inline-block;
        height: 100px;
        .delete-button-container{
            position: absolute;
            top: 4px;
            left: 4px;
        }
        .admin-image{
            height: 100px;
            aspect-ratio: 3/4;
        }
    }
}

@media (min-width: 500px) {
    .product-container{
        margin: 3px;
        width: 500px;
        display: inline-block;
    }
}
