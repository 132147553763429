header{
    z-index: 5;
    height: 100px;
    width: 100%;
    position: fixed;
    top: 0px;
    padding: 5px 0px 5px 0px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-button-container{
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo-container{
        margin-left: auto;
        margin-right: auto;
        .logo-image{
            max-height: 90px;
        }
    }

    .cart-and-profile-container{
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        .cart-container{
            position: relative;
            padding: 5px;
            cursor: pointer;
            .items{
                position: absolute;
                color: black;
                background: red;
                width: 1.25rem;
                height: 1.25rem;
                border: 0px solid black;
                border-radius: 1rem;
                text-align: center;
                top: 0;
                left: 0;
            }
            .cart-svg{
                width: 50px;
                height: 50px;
                fill: #F5CB5C;
            }
        }
        .profile-container{
            padding: 5px;
            .profile-svg{
                width: 50px;
                height: 50px;
                fill: #F5CB5C;
            }
        }
    }
}