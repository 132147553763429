.cart-card{
    padding: 4px;
    display: flex;
    margin: 5px;
    border: 2px solid white;
    border-radius: 3px;
    color: black;
    background: #E8EDDF;
    width: 330px;
    justify-content:space-evenly;
    .cart-image{
        aspect-ratio: 2/3 !important;
        max-width: 150px;
        max-height: 225px;
        object-fit: cover;
    }
    .card-title-and-buttons{
        .cart-card-title{
            font-size: 1.5rem;
        }
        .card-buttons-container{
            .cart-button{
                cursor: pointer;
                background: black;
                border: 1px solid #F5CB5C;
                border-radius: 3px;
                color: white;
                font-size: 1rem;
                height: 2.5rem;
            }
            .change-value{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .value{
                width: 2.5rem;
                margin: 4px;
            }
            .delete{
                display: block;
                margin: 5px auto 0px auto;
                width: 6rem;
            }
            .number{
                font-weight: bold;
                font-size: 1.5rem;
            }
            .price{
                font-size: 1rem;
            }
        }
    }
}

.wide{
    background: #333533;
    border: 2px solid black;
    color: white;
    width: 400px;
}