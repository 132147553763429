.card-container{
    a:link{
        text-decoration: none!important;
    }
    .card{
        overflow: hidden;
        width: 400px;
        aspect-ratio: 3/2;
        border-style: solid;
        border-width: 1px;
        border-color: black;
        color: white;
        transform: scale(1.0);
        transition: 400ms;
        background-image: linear-gradient(to right, #cfdbd5, #F5CB5C);
        box-shadow: 0px 0px 7px 6px #272727;
        background-size: 200% 200%;
        animation: gradient 15s ease infinite;

        .card-image-container {
            z-index: -1;
            position: fixed;
            width: 100%;
            height: 100%; 
            .card-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .card-title-container {
            width: 100%;
            height: 50%;
            position: fixed;
            bottom: 0rem;
            left: 0rem;
            z-index: 1;
            margin: 0;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.0) 100%);
            .card-title{
                position: fixed;
                bottom: 1rem;
                left: 1rem;
                margin: 0;
            }
        }    
    }

    .card:hover{
        transform: scale(1.03);
        transition: 400ms;
        box-shadow: 0px 0px 15px 15px #272727;
    }
}

@media (max-width: 500px){
    .card-container{
        a:link{
            text-decoration: none!important;
        }
        .card{
            width: 90vw;
            aspect-ratio: 3/2;
            
            border-style: solid;
            border-width: 1px;
            border-color: black;
            color: white;
            background-image: linear-gradient(to right, #cfdbd5, #F5CB5C);
            background-size: 200% 200%;
            animation: gradient 15s ease infinite;
            .card-image-container { 
                z-index: 0;
                position: fixed; 
                width: 100%;
                height: 100%;
    
            }
            .card-title-container {
                width: 100%;
                height: 50%;
                position: fixed;
                bottom: 0rem;
                left: 0rem;
                z-index: 1;
                margin: 0;
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.0) 100%);
                h2{
                    text-decoration: none;
                }
            }    
        }
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}