.add-to-cart-button{
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition-duration: 400ms;
    .cart-svg{
        width: 40px;
        height: 40px;
        fill: white;
    }
}

.add-to-cart-button:hover{
    transform: scale(1.03);
    transition-duration: 400ms;
    .cart-svg{
        fill: #F5CB5C;
    }
}

.has-background{
    background: #333533;
    border: 3px solid #F5CB5C;
    border-radius: 3px;
    color: white;
    padding: 10px;
    font-size: 1.5rem;
}

.has-background:hover{
    color: #F5CB5C;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}